<template>
    <div>
        <b-modal id="update-notification-type-modal" hide-footer size="md" centered title="Edit Notification Type"
            @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                    errorMsg }}</b-alert>
                <p class="mb-0">Notification Type Name</p>
                <b-form-input class="mb-1" v-model="notificationTypeName" placeholder="Notification Type Name"
                    type="text" />
                <p class="mb-0">Notification Type Description</p>
                <b-form-textarea v-model="notificationDescription" rows="6"
                    placeholder="Enter a detailed description"></b-form-textarea>
                <div class="modal-footer">
                    <b-button @click="updateTheme" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BDropdownItem,
    BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BDropdownItem,
        BFormTextarea,
        ToastificationContent,
    },
    data() {
        return {
            notificationTypeName: "",
            notificationDescription: "",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
        notificationData: function (newVal) {
            console.log("this.notificationData.name",this.notificationData.name);
            this.notificationTypeName = this.notificationData.name;
            this.notificationDescription = this.notificationData.description;
        },
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.notificationTypeName =  "",
            this.notificationDescription = "",
            this.errorMsg = "";
            this.$emit('removeNotificationData');
        },
        async updateTheme() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                let payload = {
                    _id: this.notificationData._id,
                    name: this.notificationTypeName,
                    description: this.notificationDescription
                }
                const resp = await axios.put(process.env.VUE_APP_API_URL + "/notification-type",
                    payload,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    });

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.$emit('fetchTypes');
                    this.$bvModal.hide("update-notification-type-modal");
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg = error.response.data.error || 'An error occurred';
                } else if (error.request) {
                    this.errorMsg = 'No response received from server. Please check your network connection.';
                } else {
                    this.errorMsg = 'Error in setting up the request: ' + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.notificationTypeName.length) {
                this.errorMsg = "Notification Type name is required.";
                return false;
            }

            if (this.notificationDescription.length < 50) {
                this.errorMsg = "Notification Description must be at least 50 characters.";
                return false;
            }

            return "ok";
        },
    },
    props: ["token", "notificationData"],
};
</script>
